import {
	getDefaultItemPayload,
	getTitle,
	renderToStaticMarkup,
	setDescription,
	setLinks,
	setMetaTags,
	setSchemas,
	setTitle,
} from '@wix/advanced-seo-utils/renderer'
import { convertDynamicPageModel, setExternalRouter } from '@wix/advanced-seo-utils/renderer-api'
import { getTags } from '@wix/advanced-seo-utils/async'
import { DynamicRouteData } from '@wix/thunderbolt-symbols'
import { resolveMetaTags } from './resolve-meta-tags'
import { MetaTag } from '../types'

export { getTags, getDefaultItemPayload, getTitle, setTitle, setLinks, setSchemas, setMetaTags, setDescription }

export const setWindowTitle = (title: string) => {
	if (process.env.browser) {
		window.document.title = title
	}
}

export const getStaticMarkup = (tags: any) => renderToStaticMarkup(tags).join('\n  ') // indenting every tag for final render

export const convertTPAEndpointModel = async (tpaEnapointData: any) => {
	const converters = await import('@wix/advanced-seo-utils/converters' /* webpackChunkName: "seo-api-converters" */)
	return converters.convertTpaModel(tpaEnapointData)
}

export const extractDynamicRouteData = (
	payload: DynamicRouteData,
	mediaItemUtils: any,
	currentCorvidOverrides: Array<MetaTag> = []
) => {
	if (payload) {
		const { pageHeadData = {} } = payload
		const resolvedPageHeadData = {
			...pageHeadData,
			metaTags: resolveMetaTags(pageHeadData.metaTags || {}, mediaItemUtils),
		}
		const corvidOverrides = setExternalRouter(currentCorvidOverrides, resolvedPageHeadData)
		const dynamicPageData = convertDynamicPageModel(resolvedPageHeadData)
		return {
			corvidOverrides,
			dynamicPageData,
		}
	}
}
